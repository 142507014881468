<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    overrideStyles() {
      if (this.fields.includedBackground) {
        return {
          backgroundColor: this.fields.includedBackground.color,
          color: this.fields.includedForeground.color,
        }
      }
      return {}
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage },
      )
    },
  },

  mounted() {
    defineSlice({ name: {
      label: 'Image Title Copy Link',
      group: 'playaresorts',
    }, fields: {
      topImage: { type: 'media', label: 'Top Image 1', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, name: 'topImage' },
      topImage2: { type: 'media', label: 'Top Image 2', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage2' },
      topImage3: { type: 'media', label: 'Top Image 3', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage3' },
      topImage4: { type: 'media', label: 'Top Image 4', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage4' },
      topImage5: { type: 'media', label: 'Top Image 5', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage5' },
      title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
      copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
      button: { type: 'link', label: 'Button Link', enabled: false, name: 'button' },
      included: { type: 'text', label: 'Included Banner Text', default: 'Included', enabled: false, name: 'included' },
      includedBackground: { type: 'color', label: 'Included Banner BG Color', name: 'includedBackground' },
      includedForeground: { type: 'color', label: 'Included Banner FG Color', name: 'includedForeground' },
    }, slots: [{ name: 'default', allowedElements: [] }] })
  },
}
</script>

<template>
  <div class="md:p-4 flex items-stretch image-title-copy-link text-center">
    <div
      class="flex flex-col justify-between relative mb-10 md:mb-0 pb-8 bg-abs-white w-full"
    >
      <div>
        <div class="relative overflow-hidden">
          <GeneralSwiper :devise="devise">
            <VoixMedia
              v-if="fields.topImage.value && fields.topImage.value.length"
              v-slot="slotProps"
              :field="fields.topImage"
              :background="true"
              class="w-full aspect-[16/10]"
            >
              <div
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
                class="bg-cover bg-center relative"
                style="min-height: 300px"
              />
            </VoixMedia>
            <VoixMedia
              v-if="fields.topImage2.value && fields.topImage2.value.length"
              v-slot="slotProps"
              :field="fields.topImage2"
              :background="true"
              class="w-full aspect-[16/10]"
            >
              <div
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
                class="bg-cover bg-center relative"
                style="min-height: 300px"
              />
            </VoixMedia>
            <VoixMedia
              v-if="fields.topImage3.value && fields.topImage3.value.length"
              v-slot="slotProps"
              :field="fields.topImage3"
              :background="true"
              class="w-full aspect-[16/10]"
            >
              <div
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
                class="bg-cover bg-center relative"
                style="min-height: 300px"
              />
            </VoixMedia>
            <VoixMedia
              v-if="fields.topImage4.value && fields.topImage4.value.length"
              v-slot="slotProps"
              :field="fields.topImage4"
              :background="true"
              class="w-full aspect-[16/10]"
            >
              <div
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
                class="bg-cover bg-center relative"
                style="min-height: 300px"
              />
            </VoixMedia>
            <VoixMedia
              v-if="fields.topImage5.value && fields.topImage5.value.length"
              v-slot="slotProps"
              :field="fields.topImage5"
              :background="true"
              class="w-full aspect-[16/10]"
            >
              <div
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
                class="bg-cover bg-center relative"
                style="min-height: 300px"
              />
            </VoixMedia>
          </GeneralSwiper>
          <div v-if="fields.included.enabled" class="included absolute">
            <div
              class="flex justify-center items-center uppercase text-sm text-abs-white bg-currentsite-background"
              :style="overrideStyles"
            >
              <div class="font-bold">
                {{ fields.included.value }}
              </div>
            </div>
          </div>
        </div>

        <div class="mb-8 p-8 space-y-4">
          <div class="uppercase text-2xl title">
            {{ fields.title.value }}
          </div>
          <div class="wysiwyg mb-4" v-html="fields.copy.value" />
        </div>
      </div>

      <div class="flex justify-center">
        <a
          v-if="fields.button.enabled"
          :href="fields.button.value.href"
          class="btn btn-ghost mx-8 w-[50%]"
        >
          {{ fields.button.value.text }}
        </a>
      </div>

      <div v-if="slice.elements && slice.elements.length" class="mt-4">
        <slot />
      </div>
    </div>
  </div>
</template>
