<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'The stats of a given room. The view, Square ft, max occupancy, bedding',
      fields: {
        view: { type: 'text', label: 'View' },
        footage: { type: 'text', label: 'Footage' },
        occupancy: { type: 'text', label: 'Max Occupancy' },
        bedding: { type: 'text', label: 'Bedding' },
      },
      name: { label: 'Suite Details', group: 'Hyatt' },
      templates: [{ label: 'Suite Details', fields: { bedding: { value: 'King or 2 Queen Beds' }, footage: { value: '646' }, occupancy: { value: '3' }, view: { value: 'Ocean' } } }],
    })
  },
}
</script>

<template>
  <div class="flex flex-wrap lg:flex-nowrap font-bold text-xs md:space-x-4">
    <div v-if="fields.view.value" class="flex flex-col mb-4 min-w-10 w-1/2">
      <span class="uppercase font-light mb-2 block">View</span>
      {{ fields.view.value }}
    </div>
    <div v-if="fields.footage.value" class="flex flex-col mb-4 min-w-10 w-1/2">
      <span class="uppercase font-light mb-2 block">Sq. Ft.</span>
      {{ fields.footage.value }}
    </div>
    <div v-if="fields.occupancy.value" class="flex flex-col mb-4 min-w-10 w-1/2">
      <span class="uppercase font-light mb-2 block">{{ $t('max-occupancy') }}</span>
      {{ fields.occupancy.value }}
    </div>
    <div v-if="fields.bedding.value" class="flex flex-col mb-4 min-w-10 w-1/2">
      <span class="uppercase font-light mb-2 block">Bedding</span>
      {{ fields.bedding.value }}
    </div>
  </div>
</template>
